<template>
    <div class="viewer-dialog-container">
        <v-btn elevation="2"
               color="white"
               small
               class="mb-2 max3d-btn"
               outlined
               @click="changeViewOrientation('isoView')"
               style="right: 50px;">
            <v-icon small>mdi-axis-arrow</v-icon>
        </v-btn>        
        <v-dialog v-model="dialog"
                  fullscreen
                  transition="dialog-bottom-transition">
            <template v-slot:activator="{ on, attrs }">


                <v-btn elevation="2" v-bind="attrs"
                       v-on="on"
                       color="white"
                       small
                       class="mb-2 max3d-btn"
                       outlined
                       @click="openDialog">
                    <v-icon small>mdi-arrow-expand</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                           outlined
                           @click="changeViewOrientation('isoView')">
                        isometric
                    </v-btn>
                    <v-btn color="primary"
                           outlined
                           @click="changeViewOrientation('topView')">
                        top
                    </v-btn>
                    <v-btn color="primary"
                           outlined
                           @click="changeViewOrientation('bottomView')">
                        bottom
                    </v-btn>
                    <v-btn color="primary"
                           outlined
                           @click="changeViewOrientation('frontView')">
                        front
                    </v-btn>
                    <v-btn color="primary"
                           outlined
                           @click="changeViewOrientation('sideView')">
                        side
                    </v-btn>
                    <v-btn color="primary"
                           outlined
                           @click="closeDialog()">
                        <v-icon small>mdi-close</v-icon> close
                    </v-btn>
                </v-card-actions>
                <v-divider></v-divider>
                <div id="divDialog3D" ref="divDialog3D">
                    <Viewer3D class="viewer3D" id="viewerPopUp" parentId="divDialog3D" ref="viewerId"></Viewer3D>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        name: "ViewerDialog",
        components: {
            Viewer3D: () => import("./Viewer3D.component.vue"),
        },
        watch: {},
        data() {
            return {
                dialog: false,
                materialType: "",
            };
        },
        created() {
        },
        mounted() {
        },
        methods: {
            openDialog() {
                this.$root.$emit('saveCamera', this, 'openDialog');
                this.$root.$emit('restoreCamera', this, 'openDialog');
            },
            closeDialog() {
                this.dialog = false
                this.$root.$emit('saveCamera', this, 'closeDialog');
                this.$root.$emit('restoreCamera', this, 'closeDialog');
                this.$emit('closeDialog');
            },
            changeViewOrientation(orientation) {
                this.$root.$emit('changeViewOrientation', this, orientation);
            }
        }
    }
</script>
<style lang="css" scoped>    
    .v-dialog__container {
        display: unset;
    }
    .max3d-btn {
        position: absolute;
        bottom: 0;
        right: 10px;
        width: 30px;
        min-width: 30px !important;
    }
    .viewer3D, canvas {
        padding: 0px;
        margin: 0px;
        width: 92vw !important;
        height: 92vh !important;
    }
</style>
