var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "viewer-dialog-container" },
    [
      _c(
        "v-btn",
        {
          staticClass: "mb-2 max3d-btn",
          staticStyle: { right: "50px" },
          attrs: { elevation: "2", color: "white", small: "", outlined: "" },
          on: {
            click: function($event) {
              return _vm.changeViewOrientation("isoView")
            }
          }
        },
        [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-axis-arrow")])],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", transition: "dialog-bottom-transition" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mb-2 max3d-btn",
                          attrs: {
                            elevation: "2",
                            color: "white",
                            small: "",
                            outlined: ""
                          },
                          on: { click: _vm.openDialog }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-arrow-expand")
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeViewOrientation("isoView")
                        }
                      }
                    },
                    [_vm._v(" isometric ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeViewOrientation("topView")
                        }
                      }
                    },
                    [_vm._v(" top ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeViewOrientation("bottomView")
                        }
                      }
                    },
                    [_vm._v(" bottom ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeViewOrientation("frontView")
                        }
                      }
                    },
                    [_vm._v(" front ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.changeViewOrientation("sideView")
                        }
                      }
                    },
                    [_vm._v(" side ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-close")
                      ]),
                      _vm._v(" close ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "div",
                { ref: "divDialog3D", attrs: { id: "divDialog3D" } },
                [
                  _c("Viewer3D", {
                    ref: "viewerId",
                    staticClass: "viewer3D",
                    attrs: { id: "viewerPopUp", parentId: "divDialog3D" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }